var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitUpdateEmployeeConfig($event);
            }
          }
        }, [_c('b-card', {
          attrs: {
            "body-class": "p-1"
          }
        }, [_c('h4', [_vm._v(_vm._s(_vm.$t('myAccount.employeeConfig.titleAirport')))]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-0"
        }, [_c('label', {
          staticClass: "h5",
          attrs: {
            "for": "departure-code"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.departureCode')) + " ")]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.departureCode'),
            "vid": "departure-code",
            "rules": ""
          }
        }, [_c('div', {
          staticClass: "flex-grow-1 border rounded p-25"
        }, [_c('SearchAirportSelect', {
          attrs: {
            "data-prop": _vm.newEmployeeConfig.departureCode
          },
          on: {
            "update:dataProp": function updateDataProp($event) {
              return _vm.$set(_vm.newEmployeeConfig, "departureCode", $event);
            },
            "update:data-prop": function updateDataProp($event) {
              return _vm.$set(_vm.newEmployeeConfig, "departureCode", $event);
            }
          }
        })], 1)])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-0"
        }, [_c('label', {
          staticClass: "h5",
          attrs: {
            "for": "arrival-code"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.arrivalCode')) + " ")]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.arrivalCode'),
            "vid": "arrival-code",
            "rules": ""
          }
        }, [_c('div', {
          staticClass: "flex-grow-1 border rounded p-25"
        }, [_c('SearchAirportSelect', {
          attrs: {
            "data-prop": _vm.newEmployeeConfig.arrivalCode
          },
          on: {
            "update:dataProp": function updateDataProp($event) {
              return _vm.$set(_vm.newEmployeeConfig, "arrivalCode", $event);
            },
            "update:data-prop": function updateDataProp($event) {
              return _vm.$set(_vm.newEmployeeConfig, "arrivalCode", $event);
            }
          }
        })], 1)])], 1)], 1)], 1)], 1), _c('b-card', {
          attrs: {
            "body-class": "p-1"
          }
        }, [_c('h4', [_vm._v(_vm._s(_vm.$t('myAccount.employeeConfig.titleResultSearch')))]), _c('b-row', [_c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "6"
          }
        }, [_c('h5', [_vm._v(_vm._s(_vm.$t('flight.sortBy')))]), _c('v-select', {
          staticStyle: {
            "max-width": "240px"
          },
          attrs: {
            "reduce": function reduce(val) {
              return val.value;
            },
            "label": "label",
            "options": _vm.sortItinerariesTypeOptions,
            "clearable": false
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('small', {
                staticClass: "font-weight-bolder text-truncate"
              }, [_vm._v(_vm._s(_vm.$te("flight.viewConfig.".concat(data.label)) ? _vm.$t("flight.viewConfig.".concat(data.label)) : data.label))])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('small', {
                staticClass: "font-weight-bolder text-truncate"
              }, [_vm._v(_vm._s(_vm.$te("flight.viewConfig.".concat(data.label)) ? _vm.$t("flight.viewConfig.".concat(data.label)) : data.label))])];
            }
          }], null, true),
          model: {
            value: _vm.newEmployeeConfig.sortType,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "sortType", $$v);
            },
            expression: "newEmployeeConfig.sortType"
          }
        })], 1), _c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "6"
          }
        }, [_c('h5', [_vm._v(_vm._s(_vm.$t('flight.showPrice')))]), _c('v-select', {
          staticStyle: {
            "max-width": "240px"
          },
          attrs: {
            "reduce": function reduce(val) {
              return val.value;
            },
            "label": "label",
            "options": _vm.showPriceOptions,
            "clearable": false
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('small', {
                staticClass: "font-weight-bolder text-truncate"
              }, [_vm._v(_vm._s(_vm.$te("flight.viewConfig.".concat(data.label)) ? _vm.$t("flight.viewConfig.".concat(data.label)) : data.label))])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('small', {
                staticClass: "font-weight-bolder text-truncate"
              }, [_vm._v(_vm._s(_vm.$te("flight.viewConfig.".concat(data.label)) ? _vm.$t("flight.viewConfig.".concat(data.label)) : data.label))])];
            }
          }], null, true),
          model: {
            value: _vm.newEmployeeConfig.showPriceOptions,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "showPriceOptions", $$v);
            },
            expression: "newEmployeeConfig.showPriceOptions"
          }
        })], 1), !_vm.isRoleF1 ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isShowPromotion'),
            "vid": "isShowPromotion",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.isShowPromotion,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isShowPromotion", $$v);
            },
            expression: "newEmployeeConfig.isShowPromotion"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isShowPromotion')) + " ")])], 1)])], 1) : _vm._e(), !_vm.getHideFee && !_vm.isRoleF1 ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isShowFee'),
            "vid": "isShowFee",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.isShowFee,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isShowFee", $$v);
            },
            expression: "newEmployeeConfig.isShowFee"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isShowFee')) + " ")])], 1)])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isShowFeeService'),
            "vid": "isShowFeeService",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.isShowFeeService,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isShowFeeService", $$v);
            },
            expression: "newEmployeeConfig.isShowFeeService"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isShowFeeService')) + " ")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isShowBookingClass'),
            "vid": "isShowBookingClass",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.isShowBookingClass,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isShowBookingClass", $$v);
            },
            expression: "newEmployeeConfig.isShowBookingClass"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isShowBookingClass')) + " ")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isShowAircraft'),
            "vid": "isShowAircraft",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.isShowAircraft,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isShowAircraft", $$v);
            },
            expression: "newEmployeeConfig.isShowAircraft"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isShowAircraft')) + " ")])], 1)])], 1)], 1)], 1), _c('b-card', {
          attrs: {
            "body-class": "p-1"
          }
        }, [_c('h4', [_vm._v(_vm._s(_vm.$t('myAccount.employeeConfig.titleFeature')))]), _c('b-row', [!_vm.isRoleF1 ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isPriceBargainFinder'),
            "vid": "isPriceBargainFinder",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.updateIsPriceBargainFinder($event);
            }
          },
          model: {
            value: _vm.newEmployeeConfig.isPriceBargainFinder,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isPriceBargainFinder", $$v);
            },
            expression: "newEmployeeConfig.isPriceBargainFinder"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isPriceBargainFinder')) + " ")])], 1)])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.showAddonForSms'),
            "vid": "showAddonForSms",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.showAddonForSms,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "showAddonForSms", $$v);
            },
            expression: "newEmployeeConfig.showAddonForSms"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.showAddonForSms')) + " ")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.showArrivalTimeForSms'),
            "vid": "showArrivalTimeForSms",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.showArrivalTimeForSms,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "showArrivalTimeForSms", $$v);
            },
            expression: "newEmployeeConfig.showArrivalTimeForSms"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.showArrivalTimeForSms')) + " ")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.showContactSuggestions'),
            "vid": "showContactSuggestions",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.showContactSuggestions,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "showContactSuggestions", $$v);
            },
            expression: "newEmployeeConfig.showContactSuggestions"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.showContactSuggestions')) + " ")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.employeeConfig.isInputFullNamePax'),
            "vid": "isInputFullNamePax",
            "rules": ""
          }
        }, [_c('label', {
          staticClass: "d-flex-center justify-content-start my-25 gap-2 cursor-pointer mb-0 fit-content"
        }, [_c('BFormCheckbox', {
          staticClass: "custom-control-warning",
          attrs: {
            "switch": ""
          },
          model: {
            value: _vm.newEmployeeConfig.isInputFullNamePax,
            callback: function callback($$v) {
              _vm.$set(_vm.newEmployeeConfig, "isInputFullNamePax", $$v);
            },
            expression: "newEmployeeConfig.isInputFullNamePax"
          }
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.employeeConfig.isInputFullNamePax')) + " ")])], 1)])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          staticClass: "d-flex-center gap-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "primary",
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('saveChanges')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-secondary",
            "disabled": pristine
          },
          on: {
            "click": _vm.resetNewEmployeeConfig
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reset')) + " ")])], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }